import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ContactInfo from '../components/contact_info/ContactInfo';
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/AboutUs.webp";
import SEO from '../common/SEO';
import ImageIcons from '../common/ImageIcons';
import { Link } from 'react-router-dom';

const Careers = () => {
    return (
        <>
            <SEO title="Careers - My EV Point is a leading EV charging company" description='My EV Point - the premier choice for EV charging. Reliable, fast, and convenient stations to power your journey. Learn more about us today' />
            <Header />
            <InnerPageBanner title='Careers' maxWidth='472px' innerPageBanner={aboutBanner} />
            <section className='relative md:py-[80px] max-md:py-[50px]'>
                <div className='container '>
                    <div className='grid md:grid-cols-2 lg:gap-[50px] gap-[40px] items-center'>
                        <div className='w-full md:h-[450px] sm:mh-[350px] min-h-auto'>
                            <img className='object-cover object-top h-full w-full rounded-[2px]' src={ImageIcons.hiring} alt='job Opening' />
                        </div>
                        <div className='md:py-[25px]'>
                            <h2 className='font- mb-[10px]'>We are<span className='text-[#0B7132]'> Hiring!</span></h2>
                            <p className='mb-[10px]'>We are on the hunt for the next generation of innovators with new ideas and visions to make this world a better place for everyone. If you’re one of those who knows how to carve new paths in a crowded territory, our company would love to talk to you.</p>
                            <p className='mb-[10px]'>So, what are you waiting for? </p>
                            <p className='mb-0'> Grab the opportunity to become a part of our organization. We want to hear from your side.</p>
                            <div className='mt-[20px]'>
                                <Link className='inline-block text-center duration-[400ms,700ms] px-3 min-w-[160px] md:px-3 py-3 md:py-4 bg-[#0B7132] bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full' to="https://www.thevpgroup.in/careers">Apply Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-[#f5f5f5] md:py-[80px] max-md:py-[50px]'>
                <div className='container'>
                    <div className='text-center'>
                        <h2 className='md:text-[40px] text-[30px] mb-[10px]'>Now Is <span className='font-normal'>The Time To</span> Craft Brilliance <span className='font-normal'> Together</span></h2>
                        <h3 className='font-normal text-[22px] mb-0'>If you Are Passionate About Your Work, then We Are Here to Give Your Career a New Height!</h3>
                        <h3 className='mb-0 text-[22px]'>Find your Dream Job at myEV point.</h3>
                        {/* <div className='mt-[20px]'> */}
                            {/* <Link className='inline-block text-center duration-[400ms,700ms] px-3 min-w-[160px] md:px-3 py-3 md:py-4 bg-[#0B7132] bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full' to="https://www.thevpgroup.in/careers">Apply Now</Link> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>

            <ContactInfo />
            <Footer />
        </>
    )
}

export default Careers
